// 処理のエントリポイント
const process = async (src, type, option) => {
  switch (type) {
    case 'blur':
      return _postprocessing(await _blur(_preprocessing(src)))
    case 'mozaic':
      return _postprocessing(await _mozaic(_preprocessing(src), option))
    default:
      return src
  }
}

// 前処理
// データを base64 に変換
const _preprocessing = (src) => {
  return src
}

// 後処理
// canvas を base64 に変換
const _postprocessing = (canvas) => {
  return canvas.toDataURL('image/png')
}

// ブラー処理
// base64 データを受け取り、canvas 変換処理を行った canvas を返す
const _blur = async (src) => {
  const image = await _loadImage(src)

  const canvas = document.createElement('canvas')
  canvas.style.display = 'block'
  canvas.width = image.width
  canvas.height = image.height
  const context = canvas.getContext('2d')

  context.filter = 'blur(5px)'
  context.drawImage(image, 0, 0)

  return canvas
}

// モザイク処理
// base64 データを受け取り、canvas 変換処理を行った canvas を返す
const _mozaic = async (src, option) => {
  const image = await _loadImage(src)
  const { size = 10 } = option || {}

  const [ width, height ] = [ image.width, image.height ]

  if (width % size || height % size) {
    throw new Error('Unmatched pixel ratio between src and size')
  }

  const canvas = document.createElement('canvas')
  canvas.style.display = 'block'
  canvas.width = width
  canvas.height = height
  const context = canvas.getContext('2d')

  context.drawImage(image, 0, 0)
  const imageData = context.getImageData(0, 0, width, height)
  let data = imageData.data

  let index = [...Array(size)].map((x, i) => i)

  // モザイク化する単位ごとにループを回す
  for (let y = 0; y < height; y += size) {
    for (let x = 0; x < width; x += size) {
      let _y = index.slice().map(i => (i + y) * height * 4)
      let _x = index.slice().map(i => (i + x) * 4)

      // モザイクの範囲内となる画素のインデックスを生成
      let indexes = _y
        .map(i => _x.slice().map(j => j + i))
        .reduce((a, b) => a.concat(b), [])

      // 画素を取得し、 rgb の平均を生成 & キャッシュ
      // a 値は先頭の画素から取得
      const ps = indexes.slice().map(i => data.slice(i, i + 4))
      const r = ps.reduce((a, b) => a + b[0], 0) / indexes.length
      const g = ps.reduce((a, b) => a + b[1], 0) / indexes.length
      const b = ps.reduce((a, b) => a + b[2], 0) / indexes.length
      const a = ps[0][3]

      // 対象となる画素に値を設定
      indexes.map(i => {
        data[i] = r
        data[i + 1] = g
        data[i + 2] = b
        data[i + 3] = a
      })
    }
  }

  // canvas に書き込み直し
  context.putImageData(imageData, 0, 0)
  return canvas
}

// 画像の読み込み
// 各種プロパティは load 後でないと参照できないので promise で返している
const _loadImage = (data) => {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.src = data
    image.onload = () => resolve(image)
    image.onerror = (e) => reject(e)
  })
}

export {
  process
}
