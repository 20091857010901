import LocalDataService from '@/service/LocalDataServices';

// intervalの最小単位を設定 -> これを基準に実行するのでINTERVALが変更するたびに確認
const MIN_INTERVAL = 1;
const INTERVALS = [3, 5, 10];

export default class CaptureService {
  public static initIntervalCount() {
    LocalDataService.setIntervalCount(0);
  }

  public static updateIntervalCount(): number {
    const count = LocalDataService.getIntervalCount();
    return LocalDataService.setIntervalCount(count + 1);
  }

  public static shouldCapture(interval: number): boolean {
    if (INTERVALS[interval] === 0) {
      this.initIntervalCount();
      return false;
    }
    return LocalDataService.getIntervalCount() === INTERVALS[interval];
  }

  public static getIntervalUnit() {
    return 1000 * 60 * MIN_INTERVAL;
  }

  public static updateInterval(interval: number) {
    if (interval === 2) {
      return 0;
    }
    return interval + 1;
  }

  public static getIntervalTime(interval: number) {
    return INTERVALS[interval];
  }

  public static createPixelImageUrl(url: string, pixel: number) {
    return url;
    // if (!pixel) return url
    // if (pixel === 0) return url
    // if (pixel === 1) return url.replace('/upload', '/upload/e_pixelate:10')
    // if (pixel === 2) return url.replace('/upload', '/upload/e_blur:500')
  }

  public static updatePixel(pixel: number): number {
    if (pixel === 2) {
      return 0;
    }
    return pixel + 1;
  }
}
