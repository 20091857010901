


























































import Vue from 'vue';

import { channelCreateModule } from '@/store/viewModule/channel/channelCreateViewModule';
import { userModule } from '@/store/dataModule/user/userModule';
import { UserResponse } from 'remonade-api/lib';
import { channelsModule } from '@/store/dataModule/channel/channelsModule';

interface DataType {
  newChannelName: string | null;
  newChannelUserIds: string[];
  isCreating: boolean;
}

export default Vue.extend({
  name: 'ChannelCreateContainer',

  computed: {
    isDialogOpen() {
      return channelCreateModule.isCreateDialogOpen;
    },
    teamUsers() {
      return userModule.teamUsers;
    },
  },

  data: (): DataType => {
    return {
      newChannelName: null,
      newChannelUserIds: [],
      isCreating: false,
    };
  },

  methods: {
    close() {
      channelCreateModule.setIsCreateDialogOpen(false);
    },
    async create() {
      if (this.newChannelName) {
        const createResponse = await channelsModule.createChannel(this.newChannelName);

        if ('error' in createResponse) {
          return;
        }
        this.isCreating = true;
        const channelId = createResponse.channelId;
        await Promise.all(this.newChannelUserIds.map((userId) => channelsModule.inviteUserToChannel({ channelId, userId, userType: 'general' })));
        await channelsModule.listChannels();
        this.isCreating = false;
        this.newChannelName = null;
        this.newChannelUserIds = [];
        this.close();
      }
    },
  },
});
