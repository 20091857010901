





import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { captureModule } from '@/store/viewModule/app/captureModule';
import CaptureService from '@/service/CaptureService';
import { userModule } from '@/store/dataModule/user/userModule';
const process = require('@/service/ImageProcessService').process;

@Component({
  components: {
  },
})
export default class CaptureContainer extends Vue {
  public get user() {
    return userModule.user;
  }

  public get shouldCapture() {
    return captureModule.shouldCapture;
  }

  @Watch('user')
  public onUserChanged() {
    if (captureModule.timerId < 0) {
      this.setupCapture();
    }
  }

  @Watch('shouldCapture')
  public handleCapture(value: boolean) {
    if (value) {
      this.capture();
      captureModule.setShouldCapture(false);
    }
  }

  public setupCapture() {
    if (this.user) {
      CaptureService.initIntervalCount();
      const timerId = window.setInterval(() => {
        if (this.user.snapType !== 0) {
          return;
        }
        CaptureService.updateIntervalCount();
        if (CaptureService.shouldCapture(this.user!.interval) && this.user!.snapType === 0) {
          this.capture();
        }
      // }, 5 * 1000);
      }, CaptureService.getIntervalUnit());
      captureModule.setTimerId(timerId);
    }
  }

  public async capture() {
    const video = this.$refs.video as HTMLVideoElement;
    const canvas = this.$refs.canvas as HTMLCanvasElement;
    if (this.user && video && canvas) {
      CaptureService.initIntervalCount();
      // return
      const media = navigator.mediaDevices.getUserMedia({
        video: { facingMode: 'user' },
        audio: false,
      });
      const pixel = this.user.pixel;

      media.then((stream) => {
        video.srcObject = stream;
        video.play();
        setTimeout(async () => {
          canvas.getContext('2d')!.drawImage(video, 0, 0, 300, 300);
          const image = canvas.toDataURL('image/png');

          const processType = ['nofilter', 'mozaic', 'blur'];
          const processedImage: string = await process(image, processType[pixel]);
          stream.getVideoTracks()[0].stop();
          captureModule.postSnapshot(processedImage);
        }, 2500);
      });
    }
  }

  public beforeDestroy() {
    captureModule.releaseTimer();
  }
}
