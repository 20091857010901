var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-navigation-drawer',{attrs:{"app":"","permanent":"","fixed":"","width":"228"}},[_c('v-row',{staticClass:"fill-height",attrs:{"no-gutters":""}},[_c('v-navigation-drawer',{staticClass:"drawer-overflow-visible",attrs:{"mini-variant":"","mini-variant-width":"48","permanent":"","fixed":""}},[_c('img',{staticClass:"logo",attrs:{"src":"/images/icons/icon-512x512.svg"}}),_c('v-divider'),_c('v-list',{staticClass:"px-1",staticStyle:{"overflow":"visible"},attrs:{"dense":""}},[_c('v-list-item-group',{staticClass:"mb-12",attrs:{"color":"primary"}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"relative mb-2"},[_c('v-list-item',_vm._g(_vm._b({staticClass:"relative",staticStyle:{"border-radius":"4px","overflow":"hidden"},attrs:{"link":"link","to":"/today"}},'v-list-item',attrs,false),on),[_c('v-icon',[_vm._v("mdi-check")])],1),_c('div',{staticClass:"badge today-progress-badge elevation-1 primary--text"},[_c('v-progress-circular',{attrs:{"value":_vm.myProgress,"color":_vm.myProgressColor,"size":"18","rotate":"-90"}})],1),_c('div',{staticClass:"badge today-presense-badge elevation-1 primary--text"},[_vm._v(_vm._s(_vm.presenseUsersLength))])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('today')))])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"relative mb-2"},[_c('v-list-item',_vm._g(_vm._b({staticStyle:{"border-radius":"4px","overflow":"hidden"},attrs:{"link":"link","to":"/journal"}},'v-list-item',attrs,false),on),[_c('v-icon',[_vm._v("mdi-calendar-edit")])],1),(!_vm.todayJournal)?_c('div',{staticClass:"badge small journal-badge elevation-1 white--text"}):_vm._e()],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('journal')))])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"relative mb-2"},[_c('v-list-item',_vm._g(_vm._b({staticStyle:{"border-radius":"4px","overflow":"hidden"},attrs:{"link":"link","to":"/taskpool"}},'v-list-item',attrs,false),on),[_c('v-icon',[_vm._v("mdi-format-list-bulleted-square")])],1),(_vm.poolsSuggestedToMe > 0)?_c('div',{staticClass:"badge my-taskpool-badge elevation-1 white--text"},[_vm._v(_vm._s(_vm.poolsSuggestedToMe))]):_vm._e(),(_vm.poolsSuggestedToAnyone > 0)?_c('div',{staticClass:"badge anyone-taskpool-badge elevation-1 white--text"},[_vm._v(_vm._s(_vm.poolsSuggestedToAnyone))]):_vm._e()],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('taskpool')))])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"mb-1",staticStyle:{"border-radius":"4px","overflow":"hidden"},attrs:{"link":"link","to":"/stats"}},'v-list-item',attrs,false),on),[_c('v-icon',[_vm._v("mdi-chart-line")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('stats')))])])],1),_c('v-list-item-group',{attrs:{"color":"primary"}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"mb-2",staticStyle:{"border-radius":"4px","overflow":"hidden"},attrs:{"link":"link","to":"/settings"}},'v-list-item',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cog-outline")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('settings')))])]),(_vm.teamIconTitle)?_c('v-menu',{attrs:{"top":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"mb-8",staticStyle:{"border-radius":"4px","overflow":"hidden"},attrs:{"link":"link"}},'v-list-item',attrs,false),on),[_c('v-avatar',{staticClass:"font-weight-bold",attrs:{"color":"#f0f0f0","size":"36"}},[_c('span',{},[_vm._v(_vm._s(_vm.teamIconTitle))])])],1)]}}],null,false,1341001485)},[_c('v-list',[_c('v-subheader',[_vm._v(" "+_vm._s(_vm.$t('menu.changeTeam'))+" ")]),_vm._l((_vm.myTeams),function(myTeam){return _c('v-list-item',{key:myTeam.teamId,on:{"click":function($event){return _vm.changeTeam(myTeam)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(myTeam.teamName)+" ")])],1)})],2)],1):_vm._e(),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({staticStyle:{"border-radius":"4px","overflow":"hidden"},attrs:{"link":"link","color":"danger"},on:{"click":_vm.logout}},'v-list-item',attrs,false),on),[_c('v-icon',[_vm._v("mdi-logout-variant")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('logout')))])])],1)],1)],1),_c('v-sheet',{staticStyle:{"margin-left":"48px"},attrs:{"width":"180"}},[(_vm.isFetchDataCompleted)?_c('div',[_vm._t("sidebar")],2):_vm._e()])],1)],1),_c('v-main',{attrs:{"app":""}},[(_vm.showLoader)?_c('v-progress-linear',{staticClass:"loading-bar",attrs:{"indeterminate":"","color":"primary","height":"4"}}):_vm._e(),(_vm.isFetchDataCompleted)?_c('div',[_vm._t("main")],2):_vm._e()],1),_c('task-dialog-container',{staticStyle:{"z-index":"1000"}}),_c('channel-create-container'),_vm._t("default"),_c('div',{staticClass:"fab__wrapper"},[_c('v-btn',{staticClass:"task-fab",attrs:{"fab":"","bottom":"","left":"","absolute":"","x-large":""},on:{"click":_vm.fabClicked}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-check")])],1)],1),_c('intercom-container'),_c('capture-container'),_c('v-dialog',{attrs:{"persistent":"","value":_vm.lockPage,"max-width":"720"}},[_c('v-card',{staticClass:"pa-8"},[_c('div',{staticClass:"font-weight-bold mb-8"},[_vm._v(" "+_vm._s(_vm.$t('overUsersLimit'))+" ")]),_c('v-btn',{attrs:{"color":"primary","depressed":"","large":"","to":"/settings?menu=plan"}},[_vm._v(" "+_vm._s(_vm.$t('goToTeamSetting'))+" ")])],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }