


import { userModule } from '@/store/dataModule/user/userModule';
import Vue from 'vue';
export default Vue.extend({
  watch: {
    route: {
      handler() {
        this.handleIntercom();
      },
      immediate: true,
      deep: true,
    },
    user: {
      handler() {
        this.handleIntercom();
      },
      immediate: true,
      deep: true,
    },
  },

  computed: {
    route() {
      return this.$route;
    },
    user() {
      return userModule.user;
    },
  },

  methods: {
    handleIntercom() {
      const routeName = this.$route.name;
      if (userModule.user) {
        this.$intercom.boot({
          userId: userModule.user.userId,
          name: userModule.user.username,
          email: userModule.user.email,
          team_id: userModule.user.teamId,
          user_type: userModule.user.userType,
          is_first_login: userModule.user.isFirstLogin,
        });
        if (routeName === 'settings') {
          this.$intercom.update({
            hide_default_launcher: false,
          });
        } else {
          this.$intercom.update({
            hide_default_launcher: true,
          });
        }
      }
    }
  }
});
// import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
// import { userModule } from '@/store/dataModule/user/userModule';
// import { UserResponse } from 'remonade-api/lib';

// @Component({
// })
// export default class IntercomContainer extends Vue {
//   public $intercom: any;

//   @Watch('user')
//   public onUserChanged(user: UserResponse | null) {
//     const menu = this.$route.params.menu;
//     if (user && menu) {
//       this.handleIntercom(menu);
//     }
//   }

//   @Watch('menu', { immediate: true })
//   public onParamsChanged(menu: string | null) {
//     console.log(`onParamsChanged: ${menu}`)
//     // if (this.user && menu) {
//     //   this.handleIntercom(menu);
//     // }
//   }


//   public get isMobile() {
//     if (window.innerWidth > 896) {
//       return false;
//     }
//     return true;
//   }

//   public handleIntercom(menu: string) {
//     const user = userModule.user;
//     if (user && !this.isMobile) {
//       if (menu === 'USER_SETTING' || menu === 'TEAMSETTING' ) {
//         this.$intercom.boot({
//           userId: user.userType,
//           name: user.username,
//           email: user.email,
//           team_id: user.teamId,
//           user_type: user.userType,
//           is_first_login: user.isFirstLogin,
//         });
//         this.$intercom.update({
//           hide_default_launcher: false,
//         });
//       } else {
//         this.$intercom.update({
//           hide_default_launcher: true,
//         });
//       }
//     }
//   }

//   public get user() {
//     return userModule.user;
//   }

//   public get menu() {

//     if (typeof(this.$route.query.menu) === 'string') {
//       return this.$route.query.menu;
//     }
//     return null;
//   }

//   public async mounted() {
//   }
// }
