

















import Vue from 'vue';

// components
import DesktopAppContainer from '@/container/common/DesktopAppContainer.vue';
import SettingContainer from '@/container/settings/SettingContainer.vue';
import SettingChannelsContainer from '@/container/settings/SettingChannelsContainer.vue';

import MobileAppContainer from '@/container/common/MobileAppContainer.vue';
import MobileSettingsContainer from '@/container/settings/mobile/MobileSettingsContainer.vue';

// service
import { isMobile } from '@/service/StyleService';
import { settingViewModule } from '@/store/viewModule/settings/settingViewModule';

export default Vue.extend({
  name: 'SettingScene',

  components: {
    DesktopAppContainer,
    SettingContainer,
    SettingChannelsContainer,
    MobileSettingsContainer,
    MobileAppContainer,
  },

  computed: {
    isMobile() {
      return isMobile(window);
    },
  },

  mounted() {
    const query = this.$route.query;
    if (query && query.menu) {
      const queryName = query.menu;
      switch (queryName) {
        case 'users':
          settingViewModule.selectChannel('USERS');
          break;
        case 'plan':
          settingViewModule.selectChannel('PLAN');
          break;
        default:
          break;
      }
    }
  }

});
