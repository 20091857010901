import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';

export interface ChannelCreateState {
  isCreateDialogOpen: boolean;
}

@Module({ dynamic: true, store, name: 'channel-create', namespaced: true })
class ChannelCreateModule extends VuexModule implements ChannelCreateState {
  public isCreateDialogOpen: boolean = false;

  @Mutation
  public setIsCreateDialogOpen(isOpen: boolean) {
    this.isCreateDialogOpen = isOpen;
  }
}

export const channelCreateModule = getModule(ChannelCreateModule);
