





























































import Vue from 'vue';

// components
import TaskCreateContainer from '@/container/task/TaskCreateContainer.vue';
import TodayTasksContainer from '@/container/task/TodayTasksContainer.vue';
import TaskCopyContainer from '@/container/task/TaskCopyContainer.vue';
import TaskMoveContainer from '@/container/task/TaskMoveContainer.vue';

// store
import { taskDialogModule } from '@/store/viewModule/task/taskDialogModule';

// types
import { TaskItem } from '@/model/types';
import dayjs from 'dayjs';
import { sendEvent } from '@/service/GoogleAnalyticsService';

export interface DataType {
}

export default Vue.extend({
  name: 'TaskDialogContainer',

  components: {
    TaskCreateContainer,
    TodayTasksContainer,
    TaskCopyContainer,
    TaskMoveContainer,
  },

  data: (): DataType => {
    return {
    };
  },

  computed: {
    isEditable() {
      return taskDialogModule.isEditable;
    },
    isOpen() {
      return taskDialogModule.isDialogOpen;
    },
    currentDate(): string {
      return taskDialogModule.currentDate;
    },
    minDate() {
      return taskDialogModule.minDate;
    },
    leftDayButtonDisabled() {
      if (this.currentDate === this.minDate) {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    setIsOpen(open: boolean) {
      taskDialogModule.setOpen(open);
    },
    pickDate(value: string) {
      taskDialogModule.setCurrentDate(value);

      sendEvent('today_date_change', {});
    },
    nextDate() {
      taskDialogModule.setCurrentDate(dayjs(this.currentDate).add(1, 'day').format('YYYY-MM-DD'));

      sendEvent('today_date_next', {});
    },
    prevDate() {
      taskDialogModule.setCurrentDate(dayjs(this.currentDate).subtract(1, 'day').format('YYYY-MM-DD'));

      sendEvent('today_date_prev', {});
    },
  },

  watch: {
    isOpen: {
      handler(value: boolean) {
        console.log(value);
        if (value) {
          this.$router.push({ path: this.$route.path, query: { taskDialog: true } });
        } else {
          this.$router.push({ path: this.$route.path});
        }
      },
    },
  },

  async mounted() {
    // await taskDialogModule.getTaskDialogData();
  },
});
